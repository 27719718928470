import Modal from "anchor-ui/modal";
import Button from "anchor-ui/button";
import { useState } from "react";
import { Functions, httpsCallable } from "firebase/functions";

interface User {
  uid: string;
  username?: string;
  email?: string;
  [key: string]: any; // Allow other properties
}

const SearchUserByIpModal = ({
  isOpen,
  setIsOpen,
  setAlert,
  functions,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ipAddress, setIpAddress] = useState<string>("");
  const [searchResults, setSearchResults] = useState<User[]>([]);

  // Firebase callable function
  const searchUserOnIpCallable = httpsCallable<
    { ip: string },
    User[]
  >(functions, "searchUserOnIp");

  const handleSearch = async () => {
    if (!ipAddress) {
      setAlert({ message: "Vul een IP-adres in", type: "warning" });
      return;
    }

    setIsLoading(true);
    setSearchResults([]); // Clear previous results

    try {
      const result = await searchUserOnIpCallable({ ip: ipAddress });
      const users = result.data || [];
      setSearchResults(users);
      if (users.length === 0) {
        setAlert({ message: `Geen gebruikers gevonden met IP-adres: ${ipAddress}`, type: "info" });
      }
    } catch (error: any) {
      console.error("Error searching user by IP:", error);
      setAlert({
        message: `Er is een fout opgetreden bij het zoeken naar gebruikers met IP-adres: ${ipAddress}`,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIpAddress("");
    setSearchResults([]);
  };

  return (
    <Modal
      open={isOpen}
      actions={
        <>
          <Button flatButton onClick={handleClose} disabled={isLoading}>
            SLUITEN
          </Button>
          <Button flatButton onClick={handleSearch} disabled={isLoading}>
            ZOEKEN
          </Button>
        </>
      }
      onClose={handleClose}
    >
      <h2>Zoek Gebruiker op IP-adres</h2>
      <p>Voer een IP-adres in om te zoeken naar bijbehorende gebruikers.</p>

      <div style={{ marginBottom: '16px' }}>
        <label htmlFor="ipAddress" style={{ display: 'block', marginBottom: '4px' }}>
          IP-adres:
        </label>
        <input
          type="text"
          id="ipAddress"
          value={ipAddress}
          onChange={(e) => setIpAddress(e.target.value)}
          style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}
          disabled={isLoading}
        />
      </div>

      {isLoading && <p>Bezig met zoeken...</p>}

      {searchResults.length > 0 && (
        <div>
          <h3>Zoekresultaten:</h3>
          <ul>
            {searchResults.map((user) => (
              <li key={user.uid}>
                {user.username && <span>{user.username}</span>}
                {user.email && <span> ({user.email})</span>}
                {!user.username && !user.email && <span>Gebruiker ID: {user.uid}</span>}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Modal>
  );
};

export default SearchUserByIpModal;